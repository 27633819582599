import React, { useEffect, useState, useRef } from "react";
import { Tab, Nav, Form, Col, Card, Button, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { FETCH_MARGIN_URL, PLACE_ORDER_URl } from "../../../helper/url_helper";
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { fetchOrder } from "../../../store/order/action";

export default function PushOrder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginState = useSelector((state) => state.login);
  const socketState = useSelector((state) => state.socket);
  const pushOrderState = useSelector((state) => state.pushOrder);
  const portfolioState = useSelector((state) => state.portfolio);
  const currencyRateState = useSelector((state) => state.currencyRate);
  const [instrument, setInstrument] = useState({});
  const orderStateRef = useRef(0);
  const [orderState, setOrderState] = useState({ type: "buy", qty: 1, orderType: "market", "price": 0 });
  orderStateRef.current = orderState;
  const [margin, setMargin] = useState({});
  // for firsttime page load
  useEffect(() => {
    let price = (pushOrderState.type === "buy") ? parseFloat(pushOrderState.instrument.ask_price) : parseFloat(pushOrderState.instrument.bid_price);
    setInstrument(pushOrderState.instrument)
    fetchMarginHandler(pushOrderState.instrument.token);
    setOrderState({ ...orderState, type: pushOrderState.type, qty: pushOrderState.defaultQty, "price": price });
    // console.log(pushOrderState.defaultQty)
  }, [pushOrderState]);

  // socket operation
  useEffect(() => {
    let instrument = pushOrderState.instrument
    socketState.priceSocket.on("room" + instrument.token, (instrument) => {
      setInstrument(instrument)
      if (orderStateRef.current.orderType === "market") {
        let price = (pushOrderState.type === "buy") ? parseFloat(instrument.ask_price) : parseFloat(instrument.bid_price);
        setOrderState((prevState) => ({ ...prevState, price: price }));
      }
    });
    return () => {
      // Unsubscribe from socket events
      socketState.priceSocket.off("room" + instrument.token);
    };
  }, [pushOrderState]);

  // when re change limit to market then latest price instant
  useEffect(() => {
    if (orderState.orderType === "market") {
      if (Object.keys(instrument) > 0) {
        console.log("ss", instrument);
        let price = (pushOrderState.type === "buy") ? parseFloat(instrument.ask_price) : parseFloat(instrument.bid_price);
        setOrderState((prevState) => ({ ...prevState, price: price }));
      }
    }
  }, [orderState.orderType]);

  // when qty or lot change with buttons
  function changeQtyHandler(action, isLot = false) {
    let qty = parseInt(orderState.qty);
    let lotsize = parseFloat(instrument.lotsize);
    if (action === "plus") {
      if (isLot) {
        setOrderState({ ...orderState, qty: parseInt(qty + lotsize) });
      } else {
        setOrderState({ ...orderState, qty: qty + 1 });
      }
    } else {
      if (isLot) {
        if (qty > lotsize) {
          setOrderState({ ...orderState, qty: parseInt(qty - lotsize) });
        }
      } else {
        if (qty != 1) {
          setOrderState({ ...orderState, qty: qty - 1 });
        }
      }
    }
  }

  // when qty manualy change
  function inputQtyHandler(e) {
    try {
      let qty = parseInt(e.target.value)
      if (qty >= 1) {
        setOrderState({ ...orderState, qty: qty });
      }
    } catch {

    }
  }

  // when price change with limit
  function limitPriceHandler(e) {
    if (orderState.orderType === "market") {
      return;
    }
    try {
      let price = e.target.value.trim(); // Remove leading and trailing whitespaces
      // check empty price
      if (price === "" || price === "0." || price.includes(".")) {
        setOrderState({ ...orderState, price: price })
        return;
      }
      price = parseFloat(price);
      if ((!isNaN(price)) && price >= 0) {
        setOrderState({ ...orderState, price: price });
      }
    } catch {

    }
  }


  async function fetchMarginHandler(token) {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(FETCH_MARGIN_URL, { "token": token });
      if (res.data.status === "SUCCESS") {
        setMargin(res.data.payload);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  function calculateMargin(marginFor) {
    // if unable to fetchMargin
    if ((!margin[marginFor]) || (!orderState.price) || (!orderState.qty)) {
      return "0";
    }
    const leverage = JSON.parse(JSON.stringify(margin))[marginFor][orderState.type];
    const order = JSON.parse(JSON.stringify(orderState));
    let requiredMargin;
    const price = parseFloat(order.price);
    const qty = parseFloat(order.qty);
    leverage.margin = parseFloat(leverage.margin)

    if (leverage.type === 'multiply') {
      if (leverage.margin == 0) {
        requiredMargin = qty * price;
      } else {
        if (['OPTSTK', 'OPTFUT', 'OPTIDX'].includes(instrument.segment) && order.type === 'sell') {
          // Extra margin for certain conditions
          requiredMargin = (qty * price) + ((qty * price / 100) * leverage.margin);
        } else {
          requiredMargin = (qty * price) / leverage.margin;
          if (order.currency === 'USD') {
            // Assuming CurrencyRateManager is a class with a static method 'where' and a method 'value'
            const currencyRate = parseFloat(currencyRateState[instrument.base_currency]);
            requiredMargin = requiredMargin * currencyRate;
          }
        }
      }
    } else {
      requiredMargin = (qty / parseFloat(instrument.lotsize)) * leverage.margin;
    }
    return parseFloat(requiredMargin).toFixed(2);
  }

  async function placeOrder() {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        "token": instrument.token,
        "price": orderState.price,
        "order_type": orderState.orderType,
        "type": orderState.type,
        "qty": orderState.qty,
        "from_custom_device_id": "web"
      };
      let res = await api.post(PLACE_ORDER_URl, data);
      if (res.data.status === "SUCCESS") {
        toast.success("ORDER PLACED");
        if (orderState.orderType === "limit") {
          dispatch(fetchOrder(loginState.jwttoken))
        }
        history.goBack();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className="screen">
      <div className="main-content p-0 bg-light">
        <div className="d-flex justify-content-between flex-column h-100">
          <div>
            <div className="d-flex justify-content-between align-items-center p-3" onClick={() => { history.goBack() }}>
              <div>
                <span className="d-block font-m fw-semibold d-flex align-items-center"><img className="me-2" src="images/left-arrow.svg" alt="left-arrow"></img>{instrument.trading_symbol}<span className="label-white font-s text-black ms-2">{instrument.exchange}</span></span>
                <span className="font-s font-dark-grey ms-3">LotSize: {instrument.lotsize}</span>
              </div>
              <div className="text-end">
                <span className={`d-block font-ml ${(orderState.type === "buy") ? "font-green" : "font-red"} fw-semibold`}>{(orderState.type === "buy") ? instrument.ask_price : instrument.bid_price}</span>
                <span className="font-s font-dark-grey">{instrument.price_change > 0 && '+'}{instrument.price_change} ({instrument.percentage_change}%)</span>
              </div>
            </div>
            <div className="p-3 d-flex justify-content-between bg-white">
              <Col xs={5}>
                {/* <Form> */}
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className="custom-label">Price</Form.Label>
                  <Form.Control className="custom-form-control border w-100" type="text" value={orderState.price} onChange={limitPriceHandler} readOnly={(orderState.orderType === "market")} />
                </Form.Group>
                {/* </Form> */}
              </Col>
              <Col xs={6} className="d-flex align-items-end">
                <Tab.Container id="tabs-example" defaultActiveKey="forex">
                  <Nav className={`d-flex justify-content-end watchlistbuy-tab ${orderState.type === "sell" && "red"}`} variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="inr" onClick={() => { setOrderState({ ...orderState, orderType: "limit" }) }}>LIMIT</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="forex" onClick={() => { setOrderState({ ...orderState, orderType: "market" }) }}>MARKET</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="inr">

                    </Tab.Pane>
                    <Tab.Pane eventKey="forex">

                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </div>
            <div className="p-3 bg-white">
              <Row className="g-2">
                <Col xs={6}>
                  {/* <Form> */}
                  <Form.Label className="custom-label text-black">Qty</Form.Label>
                  <div className="cart-left-side">
                    <button className={`button cartplus ${orderState.type === "sell" && "red"}`} onClick={() => { changeQtyHandler("minus") }}>-</button>
                    <Form.Control className="input border" type="text" placeholder="5" value={orderState.qty} onChange={inputQtyHandler} />
                    <button className={`button cartminus ${orderState.type === "sell" && "red"}`} onClick={() => { changeQtyHandler("plus") }}>+</button>
                  </div>
                  {/* </Form> */}
                </Col>
                <Col xs={6}>
                  {/* <Form> */}
                  <Form.Label className="custom-label text-black">Lots</Form.Label>
                  <div className="cart-left-side">
                    <button className={`button cartplus ${orderState.type === "sell" && "red"}`} onClick={() => { changeQtyHandler("minus", true) }}>-</button>
                    <Form.Control className="input border" type="text" placeholder="0.60" value={(parseFloat(orderState.qty) / parseFloat(instrument.lotsize)).toFixed(2)} readOnly={true} />
                    <button className={`button cartminus ${orderState.type === "sell" && "red"}`} onClick={() => { changeQtyHandler("plus", true) }}>+</button>
                  </div>
                  {/* </Form> */}
                </Col>
              </Row>
            </div>
            <div className="px-3 bg-white pb-3 mb-3">
              <Card className="border-0 p-0 bg-light">
                <Card.Body className="d-flex justify-content-center align-items-center flex-column">
                  <span className="text-black">Available Balance :{(instrument.currency === "INR") ? "₹" + ((parseFloat(portfolioState.balance) - parseFloat(portfolioState.used_balance))).toFixed(2) : "$" + ((parseFloat(portfolioState.balance_usd) - parseFloat(portfolioState.used_balance_usd)).toFixed(2))}</span>
                </Card.Body>
              </Card>
            </div>
          </div>


          <div className="bg-light">
            <div className="px-2 py-4 gap-2 d-flex justify-content-between pb-20 bg-white mx-3">
              <Card className="border-0 p-0 w-50">
                <Card.Body className={`${orderState.type === "buy" ? "label-green" : "label-red"} d-flex justify-content-center align-items-center flex-column px-2`}>
                  <span className="text-black font-s">Intraday : {calculateMargin("MIS")}</span>
                </Card.Body>
              </Card>
              <Card className="border-0 p-0 w-50">
                <Card.Body className={`${orderState.type === "buy" ? "label-green" : "label-red"} d-flex justify-content-center align-items-center flex-column px-2`}>
                  <span className="text-black font-s">Over night :  {calculateMargin("CNC")}</span>
                </Card.Body>
              </Card>

            </div>
            <div className="px-3 pb-4 bg-white mx-3">
              <Button className={`watchlistbuy-btn w-100 ${orderState.type === "sell" && "red"}`} onClick={placeOrder}>{orderState.type.toUpperCase()}</Button>
            </div>
          </div>
        </div>


      </div>
    </div >
  );
};

